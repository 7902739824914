import React, { Component, Fragment } from 'react';
import { Result } from 'antd';
import i18n from '../../../i18n/i18n';

class RegistrationDoneMessage extends Component<any, any> {
  public static defaultProps: Partial<any> = {};

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <Fragment>
        <Result
          status={'success'}
          title={i18n.t('register:registerPassword.success.emailMessage')}
          subTitle={
            i18n.t('register:registerPassword.success.emailDescription')
          }
        />
        <p className="saving">
          {i18n.t('register:redirectLogin')}
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </p>
      </Fragment>
    );
  };
}

export default RegistrationDoneMessage;
