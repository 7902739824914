import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions';

interface Props {
  onLogout: () => void;
}

interface State {
}

// Exporting class for test purposes 
export class Logout extends Component<Props, State> {
    componentDidMount () {
        this.props.onLogout();
    }

    render () {
        return <Redirect to="/" />;
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        onLogout: () => dispatch(actions.logout())
    };
};

export default connect(null, mapDispatchToProps)(Logout);
