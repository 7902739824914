import React, { Component, Fragment } from 'react';
import { Form, Input, Button, Icon, Alert } from 'antd';
import i18n from '../../../i18n/i18n';
import { Translation } from 'react-i18next';
import {
  IUserActivateState,
  IUserActivateProps,
} from '../../models/IUserActivate';
import { hasErrors } from '../../share/error';
class UserActivate extends Component<IUserActivateProps, IUserActivateState> {
  public static defaultProps: Partial<IUserActivateProps> = {};

  constructor(props: IUserActivateProps) {
    super(props);
    this.state = {
      user: '',
      password: '',
      registerValid: true,
      formLayout: 'horizontal',
    };
  }

  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {       
        this.props.sendRegister(values);
      }
    });
  };

  render = () => {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;
    const usernameError =
      isFieldTouched('username') && getFieldError('username');
    const passwordError =
      isFieldTouched('password') && getFieldError('password');
    const formConfig = [
      // validators email
      {
        rules: [
          {
            required: true,
            message: (
              <Translation>
                {(t) => t('register:UserActive.label.username')}
              </Translation>
            ),
          },
        ],
      },
      // validators password
      {
        rules: [
          {
            required: true,
            message: (
              <Translation>
                {(t) => t('register:UserActive.label.password')}
              </Translation>
            ),
          },
        ],
      },
    ];

    return (
      <Fragment>
        <Form
          layout="vertical"
          className="login-form"
          onSubmit={this.handleSubmit}
        >
          {/**
           * @description info box Access Data
           */}
          <Alert
            message={i18n.t('register:UserActive.alertInfo')}
            type="info"
            showIcon
            closable
            banner
          />

          {/**
           * @description show error api reponse
           */}
          {this.props.userActivateError && (
            <Alert
              message={i18n.t('register:UserActive.error.loginMessage')}
              type="error"
            />
          )}
          {/**
           * @description username input field
           */}
          <Form.Item
            label={i18n.t('register:UserActive.label.username')}
            validateStatus={usernameError ? 'error' : ''}
            help={usernameError || ''}
            hasFeedback
            
          >
            {getFieldDecorator(
              'username',
              formConfig[0]
            )(
              <Input
              className="register_username"
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder={i18n.t(
                  'register:UserActive.fields.placeholder.username'
                )}
                type="text"
              />
            )}
          </Form.Item>

          {/**
           * @description password input field
           */}
          <Form.Item
            label={i18n.t('register:UserActive.label.password')}
            validateStatus={passwordError ? 'error' : ''}
            help={passwordError || ''}
            hasFeedback
          >
            {getFieldDecorator(
              'password',
              formConfig[1]
            )(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder={i18n.t(
                  'register:UserActive.fields.placeholder.password'
                )}
              />
            )}
          </Form.Item>

          {/**
           * @description submit buttom
           */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="register-form-btn"
              placeholder="tada"
              disabled={hasErrors(getFieldsError())}
            >
              {i18n.t('register:registration.button')}
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    );
  };
}
const NewPasswordForm = Form.create<IUserActivateProps>({ name: 'register' })(UserActivate);

export default NewPasswordForm;
