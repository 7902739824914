// auth store
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAIL = 'SET_NEW_PASSWORD_FAIL';
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAIL = 'VALIDATE_TOKEN_FAIL';
//  auth store insert comjoo onboarding
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAIL = 'SET_PASSWORD_FAIL';
// dashboard store
export const INIT_DASHBOARD = 'INIT_DASHBOARD';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const INIT_DASHBOARD_FAIL = 'INIT_DASHBOARD_FAIL';
export const CHANGE_CONSENT_ITEM = 'CHANGE_CONSENT_ITEM';
export const CHANGE_CONSENT_ITEM_DISABLE = 'CHANGE_CONSENT_ITEM_DISABLE';
export const CHANGE_CONSENT_ITEM_SUCCESS = 'CHANGE_CONSENT_ITEM_SUCCESS';
export const CHANGE_CONSENT_ITEM_FAIL = 'CHANGE_CONSENT_ITEM_FAIL';
export const FETCH_DASHBOARD_FAIL = 'FETCH_DASHBOARD_FAIL';
export const ENABLE_PATIENT = 'ENABLE_PATIENT';
export const ENABLE_PATIENT_SUCCESS = 'ENABLE_PATIENT_SUCCESS';
export const ENABLE_PATIENT_FAIL = 'ENABLE_PATIENT_FAIL';
export const CREATE_FIRST_CASE = 'CREATE_FIRST_CASE';
export const ADD_CONSENT_ITEM_ACCESS_GRANTING =
  'ADD_CONSENT_ITEM_ACCESS_GRANTING';
export const ADD_CONSENT_ITEM_ACCESS_GRANTING_SUCCESS =
  'ADD_CONSENT_ITEM_ACCESS_GRANTING_SUCCESS';
export const ADD_CONSENT_ITEM_ACCESS_GRANTING_FAIL =
  'ADD_CONSENT_ITEM_ACCESS_GRANTING_FAIL';
export const CANCEL_ADD_CONSENT_ITEM_FINISH_GRATING =
  'CANCEL_ADD_CONSENT_ITEM_FINISH_GRATING';
export const ADD_CONSENT_ITEM_FINISH_GRANTING =
  'ADD_CONSENT_ITEM_FINISH_GRANTING';
export const ADD_CONSENT_ITEM_FINISH_GRANTING_SUCCESS =
  'ADD_CONSENT_ITEM_FINISH_GRANTING_SUCCESS';
export const ADD_CONSENT_ITEM_FINISH_GRANTING_FAIL =
  'ADD_CONSENT_ITEM_FINISH_GRANTING_FAIL';
// register store
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const ONESTEP_REGISTER_SUCCESS = 'ONESTEP_REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_EMAIL = 'REGISTER_EMAIL';
export const REGISTER_EMAIL2 = 'REGISTER_EMAIL2';
export const REGISTER_EMAIL_SUCCESS = 'REGISTER_EMAIL_SUCCESS';
export const REGISTER_EMAIL_FAIL = 'REGISTER_EMAIL_FAIL';
export const REGISTER_NEW_PASSWORD = 'REGISTER_NEW_PASSWORD';
export const REGISTER_NEW_PASSWORD_SUCCESS = 'REGISTER_NEW_PASSWORD_SUCCESS';
export const REGISTER_NEW_PASSWORD_FAIL = 'REGISTER_NEW_PASSWORD_FAIL';
export const REGISTER_RESET = 'REGISTER_RESET';
export const REGISTER_RESET_BUTTON = 'REGISTER_RESET_BUTTON';
export const SEND_TOKEN_EMAIL_VALIDATION = 'SEND_TOKEN_EMAIL_VALIDATION';
export const SEND_TOKEN_EMAIL_VALIDATION_SUCCESS = 'SEND_TOKEN_EMAIL_VALIDATION_SUCCESS';
export const SEND_TOKEN_EMAIL_VALIDATION_FAIL = 'SEND_TOKEN_EMAIL_VALIDATION_FAIL';
