import * as actionTypes from "./actionTypes";
import { imClient, caseManagement } from "./../../core/auth/services/axios";
import qs from "querystring";
import { auth } from "./auth";
import config from "./../../config/client";
import {
  ICredentialNewPassword,
  ICredentialOneStepRegister,
  ICredentialRegisterEmail,
  ILocalStorageRegistration,
  ICredentialTempLoginData,
  IDataCheckActivationSecret,
  IDataOneStepRegister,
} from "../models/credentialAndDataTypes";
import { AxiosResponse } from "axios";

const caseUrl = config.caseManagement;

/**
 *
 * @method register
 * @description step1 register new user
 * @param user
 * @param password
 */
export const register = (credential: ICredentialTempLoginData) => {
  const tmpUrl = "/users/prepare-activate";
  const tempCredential = {
    username: credential.username,
    password: credential.password,
  };

  return async (dispatch: any) => {
    try {
      await imClient.post(tmpUrl, tempCredential);
      dispatch(registerSuccess(credential));
    } catch (error) {
      dispatch(registerFail());
    }
  };
};

/**
 *
 * @method oneStepRegister
 * @description step1 OneStepRegister new user
 * @param activationSecret
 */
export const oneStepRegister = (credential: ICredentialOneStepRegister) => {
  const tmpUrl = "/users/check-activation-secret";
  const apiCredential = {
    activationSecret: credential.activationSecret,
  };

  return async (dispatch: any) => {
    try {
      const response: AxiosResponse<IDataCheckActivationSecret> =
        await imClient.post(tmpUrl, apiCredential);
      dispatch(oneStepRegisterSuccess(credential, response.data));
    } catch (error) {
      dispatch(registerFail());
    }
  };
};

/**
 * @method registerSuccess
 * @description save data in register LocalStorage
 * @param token
 * @param userId
 */
export const registerSuccess = (credential: ICredentialTempLoginData) => {
  /**
   * @description set date timestamp in storage
   */
  var createDate = new Date();
  var createDateTimeStamp = Math.round(createDate.getTime() / 1000);
  localStorage.setItem("timeStamp", JSON.stringify(createDateTimeStamp));
  credential.position = 1;
  localStorage.setItem("register", JSON.stringify(credential));

  return {
    type: actionTypes.REGISTER_SUCCESS,
    step: 1,
    credential: credential,
    error: false,
  };
};

/**
 * @method oneStepRegisterSuccess
 * @description save data in register LocalStorage
 * @param userEmail
 */
export const oneStepRegisterSuccess = (
  credential: ICredentialOneStepRegister,
  response: IDataCheckActivationSecret
) => {
  const data = {
    activationSecret: credential.activationSecret,
    eMail: response.eMail,
  };

  return {
    type: actionTypes.ONESTEP_REGISTER_SUCCESS,
    step: 1,
    credential: credential,
    error: false,
    data,
  };
};

/**
 * @method registerFail
 * @param error
 */
export const registerFail = () => {
  return {
    type: actionTypes.REGISTER_FAIL,
  };
};

/**
 * @method registerEmail
 * @description get Data from LocalStorage Register and current Input Field
 */
export const registerEmail = (credential: ICredentialRegisterEmail) => {
  const registerForm: any = localStorage.getItem("register");
  if (typeof registerForm === "string") {
    const register: ILocalStorageRegistration = JSON.parse(registerForm);

    return async (dispatch: any) => {
      const credentialRegisterEmail = {
        login: register.username,
        password: register.password,
        eMail: credential.email,
        position: 4,
      };

      // append to storage
      register.email = credential.email;
      register.position = 3;

      try {
        const response: AxiosResponse<string> = await imClient.post(
          "/users/activate",
          qs.stringify(credentialRegisterEmail)
        );
        dispatch(registerEmailSuccess(credential, register, response.data));
      } catch (error) {
        dispatch(registerEmailFail(false, credential));
      }
    };
  }
};

/**
 * @method registerEmailSuccess
 * @description save data in storage and change state
 */
export const registerEmailSuccess = (
  credential: ICredentialRegisterEmail,
  register: ILocalStorageRegistration,
  hash: string
) => {
  register.hash = hash;
  localStorage.setItem("register", JSON.stringify(register));
  return {
    type: actionTypes.REGISTER_EMAIL_SUCCESS,
    step: 3,
    error: false,
    credential: credential,
  };
};

/**
 * @method registerEmailFail
 * @param error
 * @param credential
 */
export const registerEmailFail = (
  error: any,
  credential: ICredentialRegisterEmail
) => {
  return {
    type: actionTypes.REGISTER_EMAIL_FAIL,
    error: error,
    username: credential.username,
    password: credential.password,
    email: credential.email,
    credential: credential,
  };
};

/**
 * @method registerNewPassword
 * @description generate new password fields old password, new password and repeat new password
 */
export const registerNewPassword = (credential: ICredentialNewPassword) => {
  const registerForm: any = localStorage.getItem("register");
  if (typeof registerForm === "string") {
    const register: ILocalStorageRegistration = JSON.parse(registerForm);
    return async (dispatch: any) => {
      const credentialNewPassword = {
        oldPassword: register.password,
        newPassword: credential.password,
      };

      try {
        await imClient.patch(
          "/users/activate/" + register.hash,
          qs.stringify(credentialNewPassword)
        );
        dispatch(registerNewPasswordSuccess());

        /**
         * @description call auth login end of onboarding process
         */
        setTimeout(() => {
          /**
           * @description see in register method fallback 3 steps sanecum
           */
          dispatch(auth(register.email, credential.password));
        }, 3000);
      } catch (error) {
        dispatch(registerNewPasswordFail(error));
      }
    };
  }
};

/**
 * @method oneStepRegisterNewPassword
 * @description generate new password and activate new user
 */
export const oneStepRegisterNewPassword = (
  credential: ICredentialNewPassword,
  data: IDataOneStepRegister
) => {
  return async (dispatch: any) => {
    const credentialNewPassword = {
      activationSecret: data.activationSecret,
      newPassword: credential.password,
    };

    try {
      await imClient.post("/users/set-password", credentialNewPassword);
      dispatch(oneStepRegisterNewPasswordSuccess());
      /**
       * @description let the user see the result of the previous step for 3s before auto-login
       */
      setTimeout(() => {
        dispatch(auth(data.email, credential.password));
      }, 3000);
    } catch (error) {
      dispatch(registerNewPasswordFail(error));
    }
  };
};

/**
 * @method registerNewPasswordSuccess
 * @description clear localStorage and
 */
export const registerNewPasswordSuccess = () => {
  const step = 4;
  localStorage.removeItem("register");
  localStorage.removeItem("timeStamp");
  return {
    type: actionTypes.REGISTER_NEW_PASSWORD_SUCCESS,
    step: step,
    registerForm: {
      username: "",
      password: "",
      email: "",
      error: false,
    },
  };
};

/**
 * @method oneStepRegisterNewPasswordSuccess
 */
export const oneStepRegisterNewPasswordSuccess = () => {
  const step = 2;

  return {
    type: actionTypes.REGISTER_NEW_PASSWORD_SUCCESS,
    step: step,
    registerForm: {
      username: "",
      password: "",
      email: "",
      error: false,
    },
  };
};

/**
 * @method registerNewPasswordFail
 */
export const registerNewPasswordFail = (error: any) => {
  return {
    type: actionTypes.REGISTER_NEW_PASSWORD_FAIL,
    error: `${error}`,
  };
};

/**
 * @method registerReset
 * @description clear local Storage step and change state
 */
export const registerReset = (credential: ILocalStorageRegistration) => {
  return {
    type: actionTypes.REGISTER_RESET,
    stepFormPosition: credential.position,
    registerForm: {
      username: credential.username !== "undefined" ? credential.username : "",
      password: credential.password !== "undefined" ? credential.password : "",
      email: credential.email !== "undefined" ? credential.email : "",
      error: false,
    },
  };
};

/**
 * @method registerReset
 * @description clear local Storage step and change state
 */
export const registerResetButton = () => {
  localStorage.removeItem("register");
  localStorage.removeItem("timeStamp");
  return {
    type: actionTypes.REGISTER_RESET,
    stepFormPosition: 0,
    registerForm: {
      username: "",
      password: "",
      email: "",
      error: false,
    },
  };
};

/**
 * @method registerCheckState
 * @description check step form register and parse state storage
 */
export const registerCheckState = () => {
  var timeStamp = localStorage.getItem("timeStamp");

  /**
   * @description clear register storage if 24 hours over
   */
  if (timeStamp !== null) {
    // current timestamp
    var currentDate = Math.round(new Date().getTime() / 1000);
    // timestamp yesterday after 24 hours
    var timeStampYesterday = currentDate - 24 * 3600;
    var is24 = parseInt(timeStamp) <= timeStampYesterday;
    if (is24) {
      localStorage.removeItem("register");
      localStorage.removeItem("timeStamp");
    }
  }

  return (dispatch: any) => {
    const register = localStorage.getItem("register");
    if (typeof register === "string") {
      const reg: ILocalStorageRegistration = JSON.parse(register);
      if (reg.position === "undefined") {
        const reg: any = {};
        reg.position = 1;
        dispatch(registerReset(reg));
      } else {
        dispatch(registerReset(reg));
      }
    }
  };
};

/**
 * @method sendTokenEmailValidation
 * @description
 */
export const sendTokenEmailValidation = (token: string) => {
  return async (dispatch: any) => {
    let url = `${caseUrl}/signup/validate-email/${token}`;

    try {
      await caseManagement.get(url);
      dispatch(sendTokenEmailValidationSuccess());
    } catch (error) {
      dispatch(sendTokenEmailValidationFail());
    }
  };
};

/**
 * @method sendTokenEmailValidationSuccess
 * @description
 */
export const sendTokenEmailValidationSuccess = () => {
  return {
    type: actionTypes.SEND_TOKEN_EMAIL_VALIDATION_SUCCESS,
  };
};

/**
 * @method sendTokenEmailValidationFail
 * @description
 */
export const sendTokenEmailValidationFail = () => {
  return {
    type: actionTypes.SEND_TOKEN_EMAIL_VALIDATION_FAIL,
  };
};
