import * as actionTypes from "./actionTypes";
import {
  fhirClient,
  easyClient,
  imClient,
} from "./../../core/auth/services/axios";
import config from "./../../config/client";
import { IItem } from "../../module/dashboard/models/IConsentList";
import { AxiosResponse } from "axios";
import { IDataFhirResponse } from "../models/credentialAndDataTypes";

const fhirUrl = config.fhirServerUrl;
const easyUrl = config.easyUrl;

/**
 * init Dashboard
 */
export const initDashboard = () => {
  return async (dispatch: any) => {
    let items: IItem[];
    try {
      const response: AxiosResponse<{ parameter: IItem[] }> =
        await fhirClient.get(
          fhirUrl + "/fhir/$retrieve-consents-for-patientdashboard"
        );
      //Checking if the user already have consents
      if (response.data.parameter) {
        // order: larger id first (aka newer)
        items = response.data?.parameter?.sort((a: IItem, b: IItem) =>
          a.name < b.name ? 1 : -1
        );
      } else {
        items = [];
      }
      dispatch(setDashboard(items));
    } catch (error) {
      dispatch(initDashboardFail());
    }
  };
};

/**
 *
 * @param items
 */
export const setDashboard = (items: IItem[]) => {
  const consents: IItem[] = items.map((e) => {
    const [name, id] = e.name.split("/");
    return {
      id,
      name,
      patientId:
        e.part!.find((p) => p.name === "patient")?.valueString || "???",
      status: e.part!.find((p) => p.name === "status")?.valueString!,
      organization: {
        name:
          e.part!.find((p) => p.name === "organization")?.valueString! || "???",
        fhirOrganizationId: e.part!.find((p) => p.name === "organizationId")
          ?.valueString!,
      },
    };
  });

  const [badFormed, wellformed] = consents.reduce<IItem[][]>(
    (parts: IItem[][], consent: IItem) => {
      const invalid: boolean =
        consent.patientId === "???" || consent.organization.name === "???";
      parts[invalid ? 0 : 1].push(consent);
      return parts;
    },
    [[], []]
  );
  if (badFormed.length > 0) console.log("Got bad formed consents", badFormed);
  return {
    type: actionTypes.SET_DASHBOARD,
    items: wellformed,
  };
};

/**
 * init Dashboard
 */
export const initDashboardFail = () => {
  return {
    type: actionTypes.INIT_DASHBOARD_FAIL,
  };
};

/**
 * @description TODO change state and need a extra param status
 * @param id
 */
export const changeConsentItem = (status: string, item: IItem) => {
  const isActivating = status?.toLowerCase().startsWith("active");

  /**
   * @description change status special case first init accept
   */
  if (status === "active proposed") {
    status = "active";
  }

  /**
   * @description change status special case first init reject
   */
  if (status === "inactive proposed") {
    status = "inactive";
  }

  return async (dispatch: any) => {
    dispatch(changeConsentItemDisable());
    if (isActivating) {
      await createFirstCase(item);
    }
    try {
      const resp: AxiosResponse<IDataFhirResponse> = await fhirClient.post(
        fhirUrl + "/fhir/Consent/" + item.id + "/$update-consent-status",
        {
          resourceType: "Parameters",
          parameter: [
            {
              name: "status",
              valueCode: status,
            },
          ],
        }
      );

      if (resp.status >= 400) {
        dispatch(changeConsentItemFail(status));
      }

      const actors = resp.data?.provision?.actor;
      const organization = actors?.find((a) =>
        a.reference?.reference?.startsWith("Organization")
      )!;
      const fhirOrganizationId: string = organization?.reference?.reference;

      // workaround to get the organizationId until fhir returns it properly when returning all consents
      const response = { fhirOrganizationId };
      dispatch(changeConsentItemSuccess(status, item, response));
      return response;
    } catch (error) {
      console.log("Change of consent status failed!", error);
      dispatch(changeConsentItemFail(status));
    }
  };
};

/**
 *
 * @param status
 * @param item
 */
export const changeConsentItemDisable = () => {
  return {
    type: actionTypes.CHANGE_CONSENT_ITEM_DISABLE,
    changeConsentDisable: true,
  };
};

/**
 *
 * @param status
 * @param item
 */
export const changeConsentItemSuccess = (
  status: string,
  item: IItem,
  response: any
) => {
  return {
    type: actionTypes.CHANGE_CONSENT_ITEM_SUCCESS,
    item: item,
    newState: status,
    response,
  };
};

/**
 *
 * @param status
 * @param item
 */
export const changeConsentItemFail = (status: string) => {
  return {
    type: actionTypes.CHANGE_CONSENT_ITEM_FAIL,
    status: status,
  };
};

/**
 * @function enablePatient
 * @param state
 * @param action
 */
export const enablePatient = (status: string, item: IItem) => {
  return async (dispatch: any) => {
    try {
      // this call apparently do nothing at all, just returns the user.
      await easyClient.post(easyUrl + "/patients/enabled");

      dispatch(enablePatientSuccess(true));
      dispatch(changeConsentItem(status, item));
    } catch (error) {
      console.log("patients/enabled failed", error);
    }
  };
};
type CaseFirst = {
  title: string;
  description: string;
  organizationId: string; // the uuid of the organization (pmId)
};
export const createFirstCase = async (item: IItem) => {
  const data: Partial<CaseFirst> = {
    title: "Nierentransplantation", // Where should this come from?
    description: item.organization?.name || "NTX",
  };
  try {
    if (!data.organizationId) {
      let fhirOrganizationId = item.organization?.fhirOrganizationId;
      if (!fhirOrganizationId) {
        console.log("looking for org id in consent");
        const fhirResponse: AxiosResponse<IDataFhirResponse> =
          await fhirClient.get(fhirUrl + "/fhir/Consent/" + item.id);

        if (fhirResponse.status === 200) {
          const actors = fhirResponse.data?.provision?.actor;
          const fhirOrganization = actors?.find((a) =>
            a.reference?.reference?.startsWith("Organization")
          );
          if (fhirOrganization?.reference?.reference) {
            fhirOrganizationId = fhirOrganization?.reference?.reference;
          }
        }
      } //else { console.log("already had fhirOrgId"); }

      // We now have the fhir-organization id, find the local one from im-api (UUID)
      const imResponse: AxiosResponse<{ organizationId: string }> =
        await imClient.get(
          "/organization/organization-id/" +
            fhirOrganizationId?.split("/").pop()!
        );

      if (imResponse.status === 200) {
        const organizationUUID = imResponse.data?.organizationId;
        data.organizationId = organizationUUID;
      }
    }
  } catch (error) {
    console.log(
      "Unexpected error while trying to find the organizationId!",
      error
    );
  }

  try {
    if (!data.organizationId) {
      // TODO Remove once it's checked it work in prod
      // if we have issues, this will work as before, so charité users are not affected.
      // but it will still be broken for all other organizations
      data.organizationId = "6301d506-78f7-45bb-b4e6-f96536143cfd";
    }
    // at this point we have a complete data object, send it
    await easyClient.post(easyUrl + "/cases/first", data); //pm-easy
  } catch (e) {
    console.log("Could not create first case.");
  }
};

/**
 *
 * @param status
 */
export const enablePatientSuccess = (status: boolean) => {
  return {
    type: actionTypes.ENABLE_PATIENT_SUCCESS,
    status: status,
  };
};

/**
 *
 * @param status
 */
export const enablePatientFail = (status: boolean) => {
  return {
    type: actionTypes.ENABLE_PATIENT_FAIL,
    status: status,
  };
};

/**
 *
 * @param status
 */
export const addConsentItemAccessGranting = (status: string) => {
  let credential = {
    resourceType: "Parameters",
    parameter: [
      {
        name: "consentHash",
        valueString: status,
      },
    ],
  };

  return async (dispatch: any) => {
    try {
      const response = await fhirClient.post(
        fhirUrl + "/fhir/$get-pending-access-granting-details",
        credential
      );
      dispatch(addConsentItemAccessGrantingSuccess(response));
    } catch (error) {
      dispatch(addConsentItemAccessGrantingFail());
    }
  };
};

/**
 *
 * @param status
 */
export const addConsentItemAccessGrantingSuccess = (response: any) => {
  const dummyConsent = {
    id: response.data.parameter[0].resource.id,
    name: "Consent/" + response.data.parameter[0].resource.id,
    part: [
      {
        name: "status",
        valueString: "PROPOSED",
      },
      {
        name: "patient",
        valueString: "Patient/", // TODO missing id
      },
      {
        name: "organization",
        valueString: response.data.parameter[1].resource.name,
      },
    ],
  };

  return {
    type: actionTypes.ADD_CONSENT_ITEM_ACCESS_GRANTING_SUCCESS,
    newConsentAccessGranting: response.data.parameter[1].resource.name, // get name Organisation
    item: dummyConsent,
  };
};

/**
 *
 * @param status
 */
export const addConsentItemAccessGrantingFail = () => {
  return {
    type: actionTypes.ADD_CONSENT_ITEM_ACCESS_GRANTING_FAIL,
  };
};

/**
 *
 * @param status
 */
export const cancelAddConsentItemFinishGranting = () => {
  return {
    type: actionTypes.CANCEL_ADD_CONSENT_ITEM_FINISH_GRATING,
  };
};

/**
 * @description TODO finish access granting
 *
 */
export const addConsentItemFinishGranting = (newConsent: string) => {
  let credential = {
    resourceType: "Parameters",
    parameter: [
      {
        name: "consentHash",
        valueString: newConsent,
      },
    ],
  };

  return async (dispatch: any) => {
    try {
      await fhirClient.post(
        fhirUrl + "/fhir/$finish-access-granting",
        credential
      );
      dispatch(addConsentItemFinishGrantingSuccess());
    } catch (error) {
      dispatch(addConsentItemFinishGrantingFail());
    }
  };
};

/**
 * @function addConsentItemFinishGrantingSuccess
 * @description add consent Item Finish Granting Success
 */
export const addConsentItemFinishGrantingSuccess = () => {
  return {
    type: actionTypes.ADD_CONSENT_ITEM_FINISH_GRANTING_SUCCESS,
  };
};

/**
 * @function addConsentItemFinishGrantingFail
 * @description add consent Item Finish Granting Fail
 */
export const addConsentItemFinishGrantingFail = () => {
  return {
    type: actionTypes.ADD_CONSENT_ITEM_FINISH_GRANTING_FAIL,
  };
};
