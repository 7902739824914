import "./DropDownMenu.scss";
import { Dropdown, Menu, Button, Icon } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import AvatarMenuItem from "./utils/AvatarMenuItem";

interface Props {
  onChange: () => void;
}

const DropDownMenu = ({ onChange }: Props) => {
  const [t] = useTranslation();

  const menu = (
    <Menu theme="dark" className="menu-dropdown">
      <Menu.Item key="1">
        <AvatarMenuItem
          icon="user"
          link="change-password"
          name={t("header.changePassword")}
        />
      </Menu.Item>
      <Menu.Item key="2">
        {
          <AvatarMenuItem
            icon="delete"
            link="deregister"
            name={t("header.btn_deregister")}
          />
        }
      </Menu.Item>
      <Menu.Item key="3">
        {
          <AvatarMenuItem
            icon="logout"
            link="logout"
            name={t("header.btn_logout")}
          />
        }
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} className="dropdown-chpsw-lo-menu">
      <Button type="primary">
        <Icon type="setting" />
      </Button>
    </Dropdown>
  );
};

export default DropDownMenu;
