import React, { FormEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Icon } from 'antd';
import i18n from '../../../i18n/i18n';
import { ILoginFormProps } from './../../models/ILoginForm';
import { hasErrors } from './../../share/error';
import { ILoginProps } from '../../models/ILogin';

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
};

const LoginForm: React.FC<ILoginFormProps> = (props: ILoginFormProps) => {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
  } = props.form;

  // Only show error after a field is touched.
  const emailError = isFieldTouched('email') && getFieldError('email');
  const passwordError = isFieldTouched('password') && getFieldError('password');

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    props.form.validateFields((err: any, values: ILoginProps) => {
      if (!err) {
        props.handleSubmit(values);
      }
    });
  };

  return (
    <Form
      {...layout}
      layout="vertical"
      autoComplete="on"
      onSubmit={handleSubmit}
    >
      <Form.Item
        label={i18n.t('login:mail')}
        hasFeedback
        validateStatus={emailError ? 'error' : ''}
      >
        {getFieldDecorator(
          'email',
          {}
        )(
          <Input
            placeholder={i18n.t('login:placeholder.email')}
            autoComplete="on"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        )}
      </Form.Item>

      <Form.Item
        label={i18n.t('login:password')}
        hasFeedback
        validateStatus={passwordError ? 'error' : ''}
      >
        {getFieldDecorator(
          'password',
          {}
        )(
          <Input.Password
            type="password"
            placeholder={i18n.t('login:placeholder.password')}
            autoComplete="on"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        )}
      </Form.Item>
      <Link to="/reset-password">
        <span className="login-info">
          {i18n.t('login:resetPassword.forgotPassword')}
        </span>
      </Link>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          disabled={hasErrors(getFieldsError())}
        >
          <span>{i18n.t('login:signIn')}</span>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
