import React, { Component, FocusEventHandler } from "react";
import { Form, Input, Button, Col, Icon, Alert } from "antd";
import i18n from "../../../i18n/i18n";
import { Translation } from "react-i18next";
import {
  IRegisterPasswordProps,
  IRegisterPasswordState,
} from "../../models/IRegisterPassword";
import { hasErrors } from "../../share/error";
import { ICredentialNewPassword } from "../../../../store/models/credentialAndDataTypes";

export class NewPasswordFormFields extends Component<
  IRegisterPasswordProps,
  IRegisterPasswordState
> {
  public static defaultProps: Partial<IRegisterPasswordProps> = {};

  constructor(props: IRegisterPasswordProps) {
    super(props);
    this.state = {
      isMatch: false,
      password: "",
      submitted: false,
      formErrors: {
        temporarypassword: false,
        password: false,
        confirm: false,
      },
      emailValid: false,
      passwordValid: false,
      formValid: false,
      confirmDirty: false,
    };
  }

  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }

  /**
   * @method handleConfirmBlur
   * @description
   */
  handleConfirmBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  /**
   * @method compareToFirstPassword
   * @description
   */
  compareToFirstPassword = (rule: any, value: string, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback(
        <Translation>
          {(t) => (
            <span>
              {t("register:registerPassword.validator.newPasswordConfirm")}
            </span>
          )}
        </Translation>
      );
    } else {
      callback();
    }
  };

  /**
   * @method validateToNextPassword
   * @description
   * @param rule
   * @param value
   * @param callback
   */
  validateToNextPassword = (rule: any, value: string, callback: any) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  /**
   * @method handleSubmit
   * @description
   */
  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: ICredentialNewPassword) => {
      if (!err) {
        this.props.registerNewPassword(values);
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      updatePasswordError,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;
    const passwordError =
      isFieldTouched("password") && getFieldError("password");
    const confirmError = isFieldTouched("confirm") && getFieldError("confirm");
    const registerFormConfig = [
      // new password
      {
        rules: [
          {
            required: true,
            message: (
              <Translation>
                {(t) => t("register:registerPassword.newPasswordEnter")}
              </Translation>
            ),
          },
          {
            validator: this.validateToNextPassword,
          },
        ],
      },
      // new password repeat
      {
        rules: [
          {
            required: true,
            message: (
              <Translation>
                {(t) => t("register:registerPassword.newPasswordRepeat")}
              </Translation>
            ),
          },
          {
            validator: this.compareToFirstPassword,
          },
        ],
      },
    ];

    return (
      <Form
        name="dynamic_form_item"
        layout="vertical"
        onSubmit={this.handleSubmit}
      >
        <Col span={24} className="easy-user-activate-form-filed-wrapper">
          {/**
           * @description show error api reponse
           */}
          {this.props.setPasswordError && (
            <Alert
              message={i18n.t("register:registerPassword.error.emailMessage")}
              type="error"
            />
          )}

          {/**
           * @description password input field
           */}
          <Form.Item
            hasFeedback
            label={
              <span>
                {i18n.t("register:registerPassword.newPasswordEnter")}
              </span>
            }
            validateStatus={passwordError ? "error" : ""}
            help={passwordError || ""}
          >
            {getFieldDecorator(
              "password",
              registerFormConfig[0]
            )(
              <Input.Password
                autoComplete="on"
                name="password"
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder={i18n.t("register:registerPassword.newPassword")}
              />
            )}
          </Form.Item>

          {/**
           * @description confirm input field
           */}
          <Form.Item
            label={
              <span>
                {i18n.t("register:registerPassword.newPasswordRepeat")}
              </span>
            }
            hasFeedback
            validateStatus={confirmError ? "error" : ""}
            {...(updatePasswordError && {
              help: updatePasswordError,
              validateStatus: "error",
            })}
          >
            {getFieldDecorator(
              "confirm",
              registerFormConfig[1]
            )(
              <Input.Password
                name="confirm"
                autoComplete="off"
                placeholder={i18n.t(
                  "register:registerPassword.newPasswordRepeat"
                )}
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                onBlur={this.handleConfirmBlur}
                type="password"
              />
            )}
          </Form.Item>

          <Col span={24} className="display-flex-center">
            {/**
             * @description submit button
             */}
            <Form.Item>
              <Button
                disabled={hasErrors(getFieldsError())}
                type="primary"
                htmlType="submit"
                size="large"
              >
                {i18n.t("register:registration.close")}
              </Button>
            </Form.Item>
          </Col>
        </Col>
      </Form>
    );
  }
}

const RegisterPassword = Form.create<IRegisterPasswordProps>({
  name: "register_password",
})(NewPasswordFormFields);

export default RegisterPassword;
