import React, { Component } from 'react';
import { Form } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { IMailCheckProps } from '../../models/IMailCheck';
import { ICredentialNewPassword, ICredentialRegisterEmail, ICredentialTempLoginData } from '../../../../store/models/credentialAndDataTypes';

// Exporting class for test purposes 
export class MailCheck extends Component<IMailCheckProps> {
  constructor(props: IMailCheckProps) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };
  }

  render = () => {
    return (
      <>hi!</>
    );
  }
}

const MailCheckPage = Form.create<IMailCheckProps>({ name: 'mail_check' })(MailCheck);

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onRegister: (credential: ICredentialTempLoginData): void =>
      dispatch(actions.register(credential)),
    registerEmail: (credential: ICredentialRegisterEmail): void =>
      dispatch(actions.registerEmail(credential)),
    registerNewPassword: (credential: ICredentialNewPassword): void =>
      dispatch(actions.registerNewPassword(credential)),
    registerResetButton: (): void =>
      dispatch(actions.registerResetButton()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()<any>(MailCheckPage));