import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import i18n from '../../../i18n/i18n';
import { Translation, withTranslation } from 'react-i18next';
import { Form, Row, Col, Typography, Alert, Result } from 'antd';
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';
import './PasswordReset.scss';
import {
  IResetPasswordProps,
  IResetPasswordState,
} from '../../models/IResetPassword';
const { Title } = Typography;

export class ResetPassword extends Component<
  IResetPasswordProps,
  IResetPasswordState
> {
  public static defaultProps: Partial<IResetPasswordProps> = {};

  constructor(props: IResetPasswordProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  /**
   * @description send email
   * @param email
   */
  handleSubmit = (email: string) => {
    this.props.resetPassword(email);
  };

  render = () => {
    const { resetPasswordSuccess } = this.props;
    return (
      <div className="wrapper">
        <Title className="page-titel">
          <Translation>
            {(t) => <span>{t('login:resetPassword.title')}</span>}
          </Translation>
        </Title>

        <Row style={{margin: '20px', padding: '10px'}}>
          <Col xs={0} sm={0} md={1} lg={2} xl={2} />
          <Col
            className="login-form-fields"
            xs={24}
            sm={24}
            md={22}
            lg={20}
            xl={20}
          >
            {!this.props.resetPasswordSuccess && (
              <Alert
              style={{margin: '20px'}}
                message={i18n.t('login:resetPassword.passwordResetInfo')}
                type="info"
                showIcon
              />
            )}
            <Row>
              <Col xs={0} sm={0} md={5} lg={7} xl={7} />
              <Col
                className="login-form-fields"
                xs={24}
                sm={24}
                md={14}
                lg={10}
                xl={10}
              >
                <ResetPasswordForm
                  resetPasswordSuccess={resetPasswordSuccess}
                  handleSubmit={this.handleSubmit}
                  form={this.props.form}
                />
              </Col>
              <Col xs={0} sm={0} md={5} lg={7} xl={7} />
            </Row>
            {this.props.resetPasswordSuccess === 1 && (
              <>
                <Result
                  status={'success'}
                  title={i18n.t('login:resetPassword.sendEmail')}
                  //subTitle={i18n.t('login:resetPassword.infoText')}
                />
                <p className="saving">
                  <Link className="ant-btn ant-btn-primary" to="/signin">
                    {i18n.t('login:resetPassword.goTologin')}
                  </Link>
                </p>
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  };
}

const ResetPasswordPage = Form.create<IResetPasswordProps>({ name: 'reset-password' })(
  ResetPassword
);

const mapStateToProps = (state: any) => {
  return {
    resetPasswordSuccess: state.auth.resetPasswordSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetPassword: (email: string) => dispatch(actions.resetPassword(email)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()<any>(ResetPasswordPage));
