import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import './FooterContent.scss';
import config from './../../../config/client';
import { Row, Col } from 'antd';
import Logo from './../../../assets/logo-comjoodoc-easy-lang-color-black.svg';
import appleStore from './../../../assets/appstore.png';
import googleStore from './../../../assets/googlestore.png';

const FooterContent: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <Row style={{ width: '100%' }}>
        <Col xs={0} sm={0} md={1} lg={1} xl={1} />
        <Col xs={24} sm={0} md={22} lg={22} xl={22} className="footer-content">
          <span className="easy-text"> {t('powerdby')}</span>
          <a className="margin-left-s" href="https://www.comjoodoc.de/">
            <img alt="Logo" src={Logo} className="footer-logo" />
          </a>
          <span>
            {`${t('comjooService')} `}
            <a className="margin-left-s" href="https://teamedio.de/">
              Teamedio GmbH
            </a>
            <hr className="footer-divider" />
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={config.appLinks.ios}
              style={{ marginLeft: '7px', marginRight: '7px' }}
            >
              <img
                src={appleStore}
                style={{ height: '30px' }}
                alt="Android Store"
              />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={config.appLinks.android}
            >
              <img
                src={googleStore}
                style={{ height: '30px' }}
                alt="Android Store"
              />
            </a>
          </span>
        </Col>
        <Col xs={0} sm={0} md={1} lg={1} xl={1} />
      </Row>
    </Fragment>
  );
};

export default FooterContent;
