import React, { useEffect, useState } from "react";

const BuildInfo: React.FC = () => {
  const [version, setVersion] = useState("");

  useEffect(() => {
    const buildInfoFile = "build.json";

    const fetchData = async () => {
      try {
        const response = await fetch(buildInfoFile);
        const info = await response.json();
        setVersion(`${info.version} - ${info.build.timestamp}`);
      } catch (error) {
        console.log("error", error);
        setVersion('-');
      }
    };
    fetchData();
  }, []);

  return <div>{version}</div>;
};

export default BuildInfo;
