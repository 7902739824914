import dev from "./dev.json";
import stage from "./stage.json";
import prod from "./prod.json";

const allConfs = {
  dev,
  stage,
  prod,
};

function loadBuildInfo() {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", "/build.json", false); // The last argument indicates synchronous
    xhr.send(null);

    if (xhr.status === 200) {
      try {
        return JSON.parse(xhr.responseText);
      } catch (e) {
        console.error("Error parsing JSON:", e);
      }
    } else {
      console.error("Failed to load JSON file");
    }
  } catch (e) {
    if (process.env.NODE_ENV !== 'test') console.error("Error accessing XMLHttpRequest :", e);
  }

  // default
  return {
    env: {
      APP_ENV: "dev"
    }
  };
}

window._buildInfo = loadBuildInfo();
const APP_ENV = window._buildInfo.env.APP_ENV || 'dev';

let domain = {
  dev: "net",
  stage: "info",
  prod: "com",
}[APP_ENV];
if (!domain) domain = "com"; // defaults to prod as before

// use env CONFIG_OVERRIDES env with values like IM_API=http://localhost:3336
const overrides = process.env.CONFIG_OVERRIDES
  ? Object.fromEntries(
    process.env.CONFIG_OVERRIDES.split(",").map((v) => v.split("="))
  )
  : {};

function value(key, defaultValue, exceptions = {}) {
  //console.log(`got ${key} with defaultValue ${defaultValue}, override: ${overrides[key]}, exception: ${exceptions[domain]}`)
  // if in env return it, otherwise exception or default
  return (
    overrides[key] || (exceptions[domain] ? exceptions[domain] : defaultValue)
  );
}
const OLD_CONF = {
  keycloak: {
    url:
      APP_ENV === "dev"
        ? "https://sso.comjoo.net/auth"
        : APP_ENV === "stage"
          ? "https://sso.comjoo.info/auth"
          : "https://sso.comjoo.com/auth",
    realm: "comjoo-hub",
    clientId: "hub-connect",
  },
  userClient: {
    url:
      APP_ENV === "dev"
        ? "https://user.api.comjoo.net"
        : APP_ENV === "stage"
          ? "https://user.api.comjoo.info"
          : "https://user-api.comjoo.com",
  },
  baseURL: value("IM_API", `https://im.api.comjoo.${domain}`, {
    com: "https://im-api.comjoo.com",
  }),
  fhirServerUrl:
    APP_ENV === "dev"
      ? "https://test-server.fhir.medworx.io"
      : APP_ENV === "stage"
        ? "https://test.fhir-stage.medworx.io"
        : "https://fhir.medworx.io",
  easyUrl:
    APP_ENV === "dev"
      ? "http://localhost:3335" || "https://pm-easy.api.comjoo.net"
      : APP_ENV === "stage"
        ? "https://pm-easy.api.comjoo.info"
        : "https://pm-easy.comjoo.com",
  caseManagement:
    APP_ENV === "dev"
      ? "https://signup.api.comjoo.net"
      : APP_ENV === "stage"
        ? "https://signup-api.comjoo.info"
        : "https://signup-api.comjoo.com",
  appLinks: {
    android: "https://play.google.com/store/apps/details?id=com.comjoo.easy",
    ios: "https://apps.apple.com/de/app/comjoodoc-easy/id1485413971",
  },
};

const CONF = allConfs[APP_ENV] || OLD_CONF;

export default CONF;
