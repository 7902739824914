import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Error.scss';
import { Typography, Row, Col, Alert, Spin } from 'antd';
import i18n from '../../../i18n/i18n';
import { connect } from 'react-redux';
import * as actions from './../../../../store/actions/index'
import { IErrorProps } from '../../models/IError';

const { Text } = Typography;

// Exporting class for test purposes 
export class ErrorPage extends Component<IErrorProps> {
  public static defaultProps: Partial<IErrorProps> = {};

  componentDidMount = () => {
    const { match } = this.props;
    const { params } = match;
    this.props.sendTokenEmailValidation(params.token)
  }

  render = () => {
    // AUTH TRIGGER
    if (this.props.isAuthenticated) {
      this.props.history.push('/dashboard');
    }

    const { tokenSuccess } = this.props;

    return (
      <div className="wrapper">
        {/*
        <Title className="page-titel">
          <Translation>{(t) => <span>{t('login:title')}</span>}</Translation>
        </Title>
        */}

        <Row>
          <Col xs={0} sm={0} md={1} lg={2} xl={2} />
          <Col
            className="login-form-fields"
            xs={24}
            sm={24}
            md={22}
            lg={20}
            xl={20}
          >
            {tokenSuccess === 1 && <Alert
              style={{ margin: 10 }}
              message={i18n.t('error:successTitle')}
              description={
                <Text>
                  {i18n.t('error:registerSuccessMessage') + ' '}
                  {/** 
                  <Link to="/signin">
                    <span className="login-info">
                      {i18n.t('register:gotoLogin')}
                    </span>
                  </Link>
                  */}
                </Text> 
              }
              type="success"
              showIcon
            />}

            {tokenSuccess === 0 &&  <Alert
            style={{ margin: 10 }}
            message={i18n.t('error:tokenTitle')}
            description={
              <Text>
                {/*i18n.t('error:tokenExpredDummy')*/}
                {/** 
                <Link to="/signin">
                  <span className="login-info">
                    {i18n.t('register:gotoLogin')}
                  </span>
                </Link>
                */}
              </Text> 
            }
            type="error"
            showIcon
          />}
          {tokenSuccess === -1 &&  ( 
            <Spin tip="Loading...">
              <Alert
                message={i18n.t('error:defaultTitle')}
                type="info"
              />
            </Spin>)}
          </Col>
          <Col xs={0} sm={0} md={1} lg={2} xl={2} />
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    tokenSuccess: state.register.tokenSuccess
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    sendTokenEmailValidation: (token: string) => dispatch(actions.sendTokenEmailValidation(token)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorPage));
