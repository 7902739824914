import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import { IStateRegister } from "./../../core/auth/models/iStateRegister";

/**
 * initial state
 */
const initialState: IStateRegister = {
  stepFormPosition: 0,
  userActivateError: false,
  eMailError: false,
  setPasswordError: false,
  registrationComplete: false,
  oneStepRegisterData: {
    activationSecret: "",
    email: "",
  },
  registerForm: {
    username: "",
    password: "",
    email: "",
    error: false,
  },
  tokenSuccess: -1,
};

/**
 *
 * @param state
 * @param action
 */
const registerStart = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    register: initialState,
    userActivateError: false,
  });
};

/**
 *
 * @param state
 * @param action
 */
const registerSuccess = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    stepFormPosition: action.step,
    userActivateError: false,
    registerForm: {
      username: action.credential.username,
      password: action.credential.password,
      email: "",
      error: false,
    },
  });
};

/**
 *
 * @param state
 * @param action
 */
const oneStepRegisterSuccess = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    stepFormPosition: action.step,
    userActivateError: false,
    oneStepRegisterData: {
      activationSecret: action.data.activationSecret,
      email: action.data.eMail,
    },
    registerForm: {
      username: "",
      password: "",
      email: "",
      error: false,
    },
  });
};

/**
 *
 * @param state
 * @param action
 */
const registerFail = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    userActivateError: true,
  });
};

/**
 *
 * @param state
 * @param action
 */
const registerEmail = (state: IStateRegister, action: any) => {
  return updateObject(state, { register: initialState, eMailError: false });
};

/**
 *
 * @param state
 * @param action
 */
const registerEmailSuccess = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    stepFormPosition: action.step,
    eMailError: false,
    registerForm: {
      username: state.registerForm.username,
      password: state.registerForm.password,
      email: action.email,
      error: false,
    },
  });
};

/**
 *
 * @param state
 * @param action
 */
const registerEmailFail = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    eMailError: true,
    registerForm: {
      username: state.registerForm.username,
      password: state.registerForm.password,
      email: action.email,
      error: true,
    },
  });
};

/**
 *
 * @param state
 * @param action
 */
const registerNewPassword = (state: IStateRegister, action: any) => {
  return updateObject(state, { register: initialState });
};

/**
 *
 * @param state
 * @param action
 */
const registerNewPasswordSuccess = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    stepFormPosition: action.step,
    registrationComplete: true,
    setPasswordError: false,
    registerForm: {
      username: "",
      password: "",
      email: "",
      error: false,
    },
  });
};

/**
 *
 * @param state
 * @param action
 */
const registerNewPasswordFail = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    registrationComplete: false,
    setPasswordError: true,
  });
};

/**
 *
 * @param state
 * @param action
 */
const registerReset = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    stepFormPosition: action.stepFormPosition,
    registerForm: {
      username: action.username,
      password: action.password,
      email: action.email,
      error: true,
    },
  });
};

/**
 *
 * @param state
 * @param action
 */
const registerResetButton = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    stepFormPosition: action.stepFormPosition,
    registerForm: {
      username: action.username,
      password: action.password,
      email: action.email,
      error: true,
    },
  });
};

const sendTokenEmailValidation = (state: IStateRegister, action: any) => {
  return updateObject(state, {});
};

const sendTokenEmailValidationSuccess = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    tokenSuccess: 1,
  });
};

const sendTokenEmailValidationFail = (state: IStateRegister, action: any) => {
  return updateObject(state, {
    tokenSuccess: 0,
  });
};

/**
 *
 * @param state
 * @param action
 */
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.REGISTER_START:
      return registerStart(state, action);
    case actionTypes.REGISTER_SUCCESS:
      return registerSuccess(state, action);
    case actionTypes.ONESTEP_REGISTER_SUCCESS:
      return oneStepRegisterSuccess(state, action);
    case actionTypes.REGISTER_FAIL:
      return registerFail(state, action);
    case actionTypes.REGISTER_EMAIL:
      return registerEmail(state, action);
    case actionTypes.REGISTER_EMAIL_SUCCESS:
      return registerEmailSuccess(state, action);
    case actionTypes.REGISTER_EMAIL_FAIL:
      return registerEmailFail(state, action);
    case actionTypes.REGISTER_NEW_PASSWORD:
      return registerNewPassword(state, action);
    case actionTypes.REGISTER_NEW_PASSWORD_SUCCESS:
      return registerNewPasswordSuccess(state, action);
    case actionTypes.REGISTER_NEW_PASSWORD_FAIL:
      return registerNewPasswordFail(state, action);
    case actionTypes.REGISTER_RESET:
      return registerReset(state, action);
    case actionTypes.REGISTER_RESET_BUTTON:
      return registerResetButton(state, action);
    case actionTypes.SEND_TOKEN_EMAIL_VALIDATION:
      return sendTokenEmailValidation(state, action);
    case actionTypes.SEND_TOKEN_EMAIL_VALIDATION_SUCCESS:
      return sendTokenEmailValidationSuccess(state, action);
    case actionTypes.SEND_TOKEN_EMAIL_VALIDATION_FAIL:
      return sendTokenEmailValidationFail(state, action);

    default:
      return state;
  }
};

export default reducer;
