import React, { Component } from "react";
import { Icon, Row, Col, Steps, Typography } from "antd";
import i18n from "../../../i18n/i18n";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import "./OneStepRegister.scss";
import AutoUserActivate from "./components/AutoUserActivate";
import RegisterPassword from "../../components/RegisterPassword/RegisterPassword";
import RegistrationDoneMessage from "../../components/RegistrationDoneMessage/RegistrationDoneMessage";
import { IAutoOneStepRegisterProps, IRegisterState } from "../../models/IRegister";
import { ICredentialNewPassword, ICredentialOneStepRegister, IDataOneStepRegister } from "../../../../store/models/credentialAndDataTypes";

const { Step } = Steps;
const { Title } = Typography;

// Exporting class for test purposes
export class OneStepRegister extends Component<IAutoOneStepRegisterProps, IRegisterState> {
  public static defaultProps: Partial<IAutoOneStepRegisterProps> = {};

  constructor(props: IAutoOneStepRegisterProps) {
    super(props);
    this.state = {
      current: 0,
      email: "",
      password: "",
      submitted: false,
      formErrors: {
        email: "",
        password: "",
        error: false,
      },
      emailValid: false,
      passwordValid: false,
      formValid: false,
    };
  }

  /**
   * @method sendRegister
   */
  sendRegister = (values: ICredentialOneStepRegister) => {
    this.props.onRegister(values);
  };

  /**
   * @method registerNewPassword
   * @param credential
   */
  registerNewPassword = (credential: ICredentialNewPassword) => {
    this.props.registerNewPassword(credential, this.props.oneStepRegisterData);
  };

  render = () => {
    const { current, userActivateError, setPasswordError } = this.props;
    //Searching quering string data
    const params = new URLSearchParams(this.props.location.search);
    //Getting activationSecret from query string
    const user: ICredentialOneStepRegister = {
      activationSecret: params.get("activationSecret")!,
    };

    const steps = [
      {
        title: i18n.t("register:activate"),
        content: (
          <AutoUserActivate
            user={user}
            userActivateError={userActivateError}
            sendRegister={this.sendRegister}
            error={false}
          />
        ),
        iconType: "user",
        id: "1",
      },
      {
        title: i18n.t("register:stepTitleRegisterPassword"),
        content: (
          <RegisterPassword
            setPasswordError={setPasswordError}
            registerNewPassword={this.registerNewPassword}
            error={false}
          />
        ),
        iconType: "lock",
        id: "2",
      },
      {
        title: i18n.t("register:done"),
        content: <RegistrationDoneMessage />,
        iconType: "smile-o",
        id: "3",
      },
    ];

    return (
      <div className="wrapper">
        <Title className="page-titel">{i18n.t("register:page_titles")}</Title>
        {/** TODO REFACTORE CREATE STEPFORM COMPONENT */}

        <Row gutter={[{ xs: 32, sm: 24, md: 24, lg: 24 }, 20]}>
          <Col xs={1} sm={1} md={2} lg={2} xl={2} />
          <Col xs={22} sm={22} md={20} lg={20} xl={20}>
            {/* create stepform header */}
            <Steps size="small" current={current}>
              {steps.map((item) => (
                <Step
                  icon={
                    <Icon
                      type={item.iconType}
                      style={{
                        color:
                          item.iconType === "close-circle" ? "gray" : "null",
                      }}
                    />
                  }
                  key={item.title}
                  title={item.title}
                />
              ))}
            </Steps>
          </Col>
          <Col xs={1} sm={1} md={2} lg={2} xl={2} />
        </Row>

        <Row gutter={[{ xs: 32, sm: 16, md: 8, lg: 2 }, 20]}>
          <Col xs={0} sm={0} md={2} lg={2} xl={2} />
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <Col
              style={{
                background: "#fff",
                boxSizing: "border-box",
              }}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
            >
              <Row>
                <Col xs={0} sm={0} md={2} lg={6} xl={6} />
                <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                  {/* steps content */}
                  <div className="steps-content">{steps[current].content}</div>
                </Col>
                <Col xs={0} sm={0} md={2} lg={6} xl={6} />
              </Row>
              <Col xs={0} sm={0} md={1} lg={1} xl={1} />
              <Col xs={0} sm={0} md={1} lg={1} xl={1} />
            </Col>
          </Col>
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.register.step,
    error: state.register.error,
    current: state.register.stepFormPosition,
    userActivateError: state.register.userActivateError,
    eMailError: state.register.eMailError,
    setPasswordError: state.register.setPasswordError,
    registrationComplete: state.register.registrationComplete,
    oneStepRegisterData: state.register.oneStepRegisterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onRegister: (credential: ICredentialOneStepRegister): void =>
      dispatch(actions.oneStepRegister(credential)),
    registerNewPassword: (credential: ICredentialNewPassword, data: IDataOneStepRegister): void =>
      dispatch(actions.oneStepRegisterNewPassword(credential, data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()<any>(OneStepRegister));
