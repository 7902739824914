import jwt_decode from "jwt-decode";

export function readToken() {
  const token: string | null = localStorage.getItem("token");
  if (!token) return {};
  try {
    const decoded: any = jwt_decode(token);
    return {
      id: decoded.hubId,
      kcId: decoded.id,
      email: decoded.email,
      name: decoded.name || decoded.preferred_username,
    };
  } catch (e) {
    console.log(e);
    return {};
  }
}
