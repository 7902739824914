import { Icon } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import {Row} from "antd"

const AvatarMenuItem = ({ icon, link, name }) => (
  <Row style={{ justifyContent: 'space-between', margin: '3px 0px' }}>
    <Link to={`/${link}`}>
      <Icon style={{ fontSize: 16, color: "#eef0f4", marginRight: 6 }} type={icon} />
      <span style={{ fontSize: 16, color: "#eef0f4" }}>{name}</span>
    </Link>
  </Row>
);

export default AvatarMenuItem;
