import { Alert, Button, Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import "./Deregister.scss";
import { readToken } from '../../services/token';

const { Title } = Typography;
export function DeregisterPage() {
  const [emailUrl, setEmailUrl] = useState<string|undefined>();

  useEffect(()=>{
    const decoded = readToken();
    if (decoded.email) {
      const email = 'mailto:service@teamedio.de'
      const subject = `Account Deletion ${decoded.id}`;
      const body = `Hello Teamedio,

I want my data to be removed. I understand this action cannot be undone.

id: ${decoded.id}
user: ${decoded.email}

Thanks.
`;  
      setEmailUrl(`${email}?subject=${encodeURI(subject)}&body=${encodeURI(body)}`)
    }
  }, [ ])
  
  return (
    <div style={{overflow:'auto'}}>
      <Title className="page-titel">
        <Translation>{(t) => <span>{t('deregister:pageTitle')}</span>}</Translation>
      </Title>
      <Row><Col><div className="panel">
        <Title>
          <Translation>{(t) => <span>{t('deregister:yourRightsTitle')}</span>}</Translation>
        </Title>
        <Translation>{(t) => <div>{t('deregister:yourRightsText')}</div>}</Translation>

        <Title>
          <Translation>{(t) => <span>{t('deregister:importantInfoTitle')}</span>}</Translation>
        </Title>
        <Translation>{(t) => <div>{t('deregister:importantInfoText')}</div>}</Translation>
        <Translation>{(t) => <div>{t('deregister:importantInfoText2')}</div>}</Translation>
        <Translation>{(t) => (<div>
          {t('deregister:importantInfoText3')}
          <Button type="link" href="/dashboard">
            {t('deregister:importantInfoText3Link')}
          </Button>

          </div>)}</Translation>
        
        
        <Title>
          <Translation>{(t) => <span>{t('deregister:howToTitle')}</span>}</Translation>
        </Title>
        <Translation>{(t) => <div>{t('deregister:howToText')}</div>}</Translation>
        <div className='centerContainer'>
          {emailUrl ? 
          <Button type="primary" href={emailUrl}>
            <Translation>{(t) => <span>{t('deregister:mailButton')}</span>}</Translation>
          </Button>
          :
          <div>
            <Button type="primary" href="signin?redirect=/deregister">
            <Translation>{(t) => <span>{t('deregister:pleaseLogin')}</span>}</Translation>
          </Button>
          </div>
          }
        </div>
        <Title>
          <Translation>{(t) => <span>{t('deregister:warningTitle')}</span>}</Translation>
        </Title>
        <div>
          <ul>
            <li><Translation>{(t) => <div>{t('deregister:warningPoint1')}</div>}</Translation></li>
            <li><Translation>{(t) => <div>{t('deregister:warningPoint2')}</div>}</Translation></li>
          </ul>
        </div>
        <div>
            <Alert
              style={{ margin: 10 }}
              message={
                <Row>
                  <Col className="alert-message">
                    <Translation>{(t) => <span>{t('deregister:contact')}</span>}</Translation>
                    <a href="mailto:service@teamedio.de"
                      target="_blank" rel="noopener noreferrer">
                      Teamedio Service Team
                    </a>
                  </Col>
                </Row>
              }
              type="info"
              showIcon
              closable
              banner
            />
            {/* <Alert
          style={{ margin: 10 }}
          message={
            <Row>
              <Col className="alert-message">
                <Text>
                  {i18n.t('deregister:warning')}
                </Text>
              </Col>
            </Row>
          }
          type="error"
          showIcon
          closable
          banner
        /> */}
          </div>
        </div></Col></Row>

    </div>);
}