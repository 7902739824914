import React, { Fragment } from "react";
import LanguageSwitch from "./LanguageSwitch";
import "./HeaderContent.scss";
import comjooLogo from "./../../../assets/logo-comjoodoc-easy-lang-color-white.png";
import DropDownMenu from "./DropDownMenu";

const logos = {
  logoPath: comjooLogo,
  width: 120,
  title: "title",
};

interface Props {
  isAuthenticated: boolean;
  onChange: () => void;
}

const HeaderContent = (props: Props) => {
  const styles = {
    lineHeight: "60px",
    backgroundColor: "transparent",
  };

  return (
    <Fragment>
      <div className="menuuser" style={styles}>
        <LanguageSwitch />
        {props.isAuthenticated ? (
          <DropDownMenu onChange={props.onChange} />
        ) : null}
      </div>
      <div className="logo">
        <img
          src={logos.logoPath}
          alt="logo"
          style={{
            width: logos.width,
            marginTop: "-7px",
          }}
        />
      </div>
    </Fragment>
  );
};

export default HeaderContent;
