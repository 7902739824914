import { Layout } from "antd";
import React, { Component, Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import OverwritePassword from "../../../core/auth/components/OverwritePassword/OverwritePassword";
import Error from "../../../core/auth/pages/Error/Error";
import Login from "../../../core/auth/pages/Login/Login";
import Logout from "../../../core/auth/pages/Logout/Logout";
import NewPassword from "../../../core/auth/pages/NewPassword/NewPassword";
import Register from "../../../core/auth/pages/Register/Register";
import MailCheck from "../../../core/auth/pages/MailCheck/MailCheck";
import ResetPassword from "../../../core/auth/pages/ResetPassword/ResetPassword";
import FooterContent from "../Footer/FooterContent";
import HeaderContent from "../Header/HeaderContent";
import claim_logo from "./../../../assets/comjoodoc_logo_claim.png";
import * as actions from "./../../../store/actions/index";
import "./app.scss";
import OneStepRegister from "../../../core/auth/pages/OneStepRegister/OneStepRegister";
import { DeregisterPage } from "../../../core/auth/pages/Deregister/Deregister";

// Lazy Loading
const AsyncDashboard = lazy(
  () => import("./../../../module/dashboard/pages/Dashboard/Dashboard")
);
const { Header, Footer } = Layout;

const meta = {
  title: "comjoodoc EASY",
  description: "comjoodoc EASY",
  favicon: `${process.env.PUBLIC_URL}/favicon.ico`,
  manifest: `${process.env.PUBLIC_URL}/manifest.json`,
};

interface Props {
  onTryAutoSignup: () => void;
  onTryAutoRegisterFormPosition: () => void;
  logOut: () => void;
  isAuthenticated: boolean;
  history: any;
}

interface State {}

// Exporting class for test purposes 
export class App extends Component<Props, State> {
  public static defaultProps: Partial<Props> = {};
  public auth: any = null;

  /**
   * @description init process auth check and register stepform position
   */
  componentDidMount = () => {
    this.props.onTryAutoSignup();
    this.props.onTryAutoRegisterFormPosition();
  };

  /**
   * dispatch logout redux
   */
  logout = () => {
    this.props.logOut();
  };

  /**
   *
   */
  render = () => {

    let routes = (
      <Switch>
        <Route path="/register" exact component={Register}></Route>
        <Route path="/deregister" exact component={DeregisterPage}></Route>
        <Route path="/one-step-register" exact component={OneStepRegister}></Route>
        <Route path="/signin" exact component={Login}></Route>
        <Route path="/mail-check" exact component={MailCheck}></Route>
        <Route path="/logout" exact component={Logout}></Route>
        <Route path="/validate-email/:token" component={Error}></Route>
        <Route path="/reset-password" exact component={ResetPassword}></Route>
        <Route
          path="/new-password/:token/:project"
          component={NewPassword}
        ></Route>
        <Route path="/new-password/:token" component={NewPassword}></Route>
        <Redirect to="/signin" />
      </Switch>
    );

    // flag trigger guard route Authentication
    if (this.props.isAuthenticated) {
      const redirect = new URLSearchParams(this.props.history.location.search).get("redirect") || "/dashboard"
  
      routes = (
        <Switch>
          <Route path="/dashboard" exact component={AsyncDashboard}></Route>
          <Route path="/logout" exact component={Logout}></Route>
          <Route path="/deregister" exact component={DeregisterPage}></Route>
          <Route path="/dashboard" component={AsyncDashboard}></Route>
          <Route path="/change-password" component={OverwritePassword}></Route>
          <Route path="/new-password/:token" component={NewPassword}></Route>
          <Redirect to={redirect} />
        </Switch>
      );
    }

    return (
      <Fragment>
        {/*
        Programmatically change meta information based on the selected theme
        */}
        <Helmet>
          <title>{meta.title}</title>
          <meta
            name="description"
            content={meta.description}
          />
          <link rel="icon" href={meta.favicon} />
          <link rel="manifest" href={meta.manifest} />
        </Helmet>
        <Router>
          <Suspense
            fallback={
              <div
                style={{
                  backgroundColor: "rgba(238, 238, 238, .3)",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
              >
                <div className="loading-content">
                  {" "}
                  <img
                    style={{ width: "30vw" }}
                    src={claim_logo}
                    alt="Logo"
                  />{" "}
                </div>
              </div>
            }
          >
            <Header
              className="layout-content-header"
              style={{ width: "100%", backgroundColor: "#313237" }}
            >
              <HeaderContent
                onChange={this.logout}
                isAuthenticated={this.props.isAuthenticated}
              />
            </Header>
            <Layout>{routes}</Layout>
            <Footer className="app-footer">
              <FooterContent />
            </Footer>
          </Suspense>
        </Router>
      </Fragment>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onTryAutoRegisterFormPosition: () => dispatch(actions.registerCheckState()),
    logOut: () => dispatch(actions.logout()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
