import React, { FormEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Icon, Alert } from 'antd';
import i18n from '../../../i18n/i18n';
import { Translation } from 'react-i18next';
import { hasErrors } from './../../share/error';
import { IResetPasswordFormProps } from '../../models/IResetPasswordForm';
import './ResetPasswordForm.scss';

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
};

const ResetPasswordForm: React.FC<IResetPasswordFormProps> = (props: IResetPasswordFormProps) => {
  const {
    getFieldDecorator,
    getFieldsError,
  } = props.form;

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      if (!err) {
        if (values['email'] !== undefined && values['email'] !== null) {
          props.handleSubmit(values['email']);
        }
      }
    });
  };

  return (
    <Form
    className='reset-password'
      {...layout}
      layout="vertical"
      autoComplete="on"
      onSubmit={handleSubmit}
    >
      {props.resetPasswordSuccess === 2 && (
        <>
          <Alert message={i18n.t('login:error.emailNotExists')} type="error" />
        </>
      )}
      {props.resetPasswordSuccess === 0 || props.resetPasswordSuccess === 2 ? (
        <>
          <Form.Item
            label={i18n.t('login:mail')}
            hasFeedback
          >
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: (
                    <Translation>
                      {(t) => <span>{t('login:error.validateEmail')}</span>}
                    </Translation>
                  ),
                },
                {
                  required: true,
                  message: (
                    <Translation>
                      {(t) => <span>{t('login:error.enterEmail')}</span>}
                    </Translation>
                  ),
                },
              ],
            })(
              <Input
                placeholder={i18n.t('login:placeholder.email')}
                autoComplete="on"
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />
            )}
          </Form.Item>
          <Link to="/signin">
            <span className="login-info">
              {i18n.t('login:resetPassword.goTologin')}
            </span>
          </Link>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={hasErrors(getFieldsError())}
            >
              <span>{i18n.t('login:button.submit')}</span>
            </Button>
          </Form.Item>
        </>
      ) : null}
    </Form>
  );
};

export default ResetPasswordForm;
