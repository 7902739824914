import axios, { AxiosRequestConfig } from "axios";
import config from "./../../../config/client";

/**
 * @description ssoClient client with interceptors
 */
const ssoClient = axios.create({
  baseURL: config.keycloak.url,
});
ssoClient.defaults.baseURL = config.fhirServerUrl;
ssoClient.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
/**
 * @description ssoClient request interceptors
 */
ssoClient.interceptors.request.use(
  (request) => {
    request.headers.common["Content-Type"] =
      "application/x-www-form-urlencoded";
    const storageTokenString = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    const token =
      storageTokenString !== "undefined" && storageTokenString !== ""
        ? "Bearer " + storageTokenString
        : "AUTH TOKEN FROM INSTANCE";
    request.headers.Authorization = token;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description ssoClient response interceptors
 */
ssoClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description userClient client without interceptors
 */
const userClient = axios.create({
  baseURL: config.userClient.url,
});
userClient.defaults.baseURL = config.userClient.url;
userClient.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

/**
 * @description baseUrlClient client with interceptors
 */
const baseUrlClient = axios.create({
  baseURL: config.fhirServerUrl,
});
baseUrlClient.defaults.baseURL = config.fhirServerUrl;

/**
 * @description baseUrlClient request interceptor
 */
baseUrlClient.interceptors.request.use(
  (request) => {
    //axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    const storageTokenString = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    const token =
      storageTokenString !== "undefined" && storageTokenString !== ""
        ? "Bearer " + storageTokenString
        : "AUTH TOKEN FROM INSTANCE";
    request.headers.Authorization = token;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description baseUrlClient response interceptor
 */
baseUrlClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description fhir client with interceptors
 */
const fhirClient = axios.create({
  baseURL: config.fhirServerUrl,
});
fhirClient.defaults.baseURL = config.fhirServerUrl;
fhirClient.defaults.headers.post["Content-Type"] = "multipart/form-data";
/**
 * @description fhir request interceptor
 */
fhirClient.interceptors.request.use(
  (request) => {
    //axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    request.headers.common["Content-Type"] = "multipart/form-data";
    const storageTokenString = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    const token =
      storageTokenString !== "undefined" && storageTokenString !== ""
        ? "Bearer " + storageTokenString
        : "AUTH TOKEN FROM INSTANCE";
    request.headers.Authorization = token;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description fhirClient response interceptor
 */
fhirClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description easy client with interceptors
 */
const easyClient = axios.create({
  baseURL: config.easyUrl,
});

/**
 * @description easyClient request interceptor
 */
easyClient.interceptors.request.use(
  (request) => {
    // TODO axios.defaults.headers.post['Content-Type'] = 'application/json';
    const storageTokenString = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    const token =
      storageTokenString !== "undefined" && storageTokenString !== ""
        ? "Bearer " + storageTokenString
        : "AUTH TOKEN FROM INSTANCE";
    request.headers.Authorization = token;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description easyClient response interceptor
 */
easyClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description caseManagement with interceptors
 */
const caseManagement = axios.create({
  baseURL: config.easyUrl,
});

/**
 * @description caseManagement request interceptor
 */
caseManagement.interceptors.request.use(
  (request) => {
    // TODO axios.defaults.headers.post['Content-Type'] = 'application/json';
    //const storageTokenString = (localStorage.getItem('token')) ? localStorage.getItem('token') : "";
    //const token = storageTokenString !== "undefined" && storageTokenString !== "" ? 'Bearer ' + storageTokenString : 'AUTH TOKEN FROM INSTANCE';
    //request.headers.Authorization =  token;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description caseManagement response interceptor
 */
caseManagement.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const imClient = axios.create({
  baseURL: config.baseURL,
});

// TODO: refactor all others to use these functions or similar
const tokenInterceptor = (request: AxiosRequestConfig) => {
  const storageTokenString = localStorage.getItem("token") || "";
  const token =
    storageTokenString !== "undefined" && storageTokenString !== ""
      ? "Bearer " + storageTokenString
      : "AUTH TOKEN FROM INSTANCE";
  request.headers.Authorization = token;
  return request;
};
const errorHandler = (error: any) => Promise.reject(error);

imClient.interceptors.request.use(tokenInterceptor, errorHandler);

export {
  ssoClient,
  userClient,
  fhirClient,
  baseUrlClient,
  easyClient,
  caseManagement,
  imClient,
};
