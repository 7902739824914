import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider,  } from 'react-redux';
import {  combineReducers } from 'redux';
import authReducer from './store/reducers/auth';
import registerReducer from './store/reducers/register';
import dashboardReducer from './store/reducers/dashboard';
import './index.scss';
import App from './components/ui/App/App';
import * as serviceWorker from './serviceWorker';
import { configureStore,  } from '@reduxjs/toolkit'
declare global {
  interface Window {
    _env_: any;
  }
}

const rootReducer = combineReducers({
    auth: authReducer,
    register: registerReducer,
    dashboard: dashboardReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .prepend()
});

const app = (
  <Provider store={store}>
      <BrowserRouter>
          <App />
      </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
