import React, { Fragment, useEffect } from "react";
import { Alert, Button, Spin } from "antd";
import i18n from "../../../../i18n/i18n";
import { ICredentialOneStepRegister } from "../../../../../store/models/credentialAndDataTypes";

interface AutoUserActivateProps {
  sendRegister: (credential: ICredentialOneStepRegister) => void;
  user: ICredentialOneStepRegister;
  userActivateError: boolean;
  error: boolean;
}
const AutoUserActivate = ({
  sendRegister,
  user,
  userActivateError,
}: AutoUserActivateProps) => {
  //Start sendRegister process as soon as component is mounted.
  useEffect(() => {
    activateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activateUser = () => {
    try {
      sendRegister({
        activationSecret: user.activationSecret,
      });
    } catch (error: any) {
      console.log("there was an error with sendRegister");
    }
  };

  return (
    <Fragment>
      <h2>We are activating your account, it may take a few seconds..</h2>
      <>
        <br />
        <Spin />
        {userActivateError && (
          <Button
            className="btn-test"
            type="primary"
            onClick={() => activateUser()}
          >
            Try again
          </Button>
        )}
      </>
      {userActivateError && (
        <>
          <br />
          <br />
          <Alert
            message={i18n.t("register:UserActive.error.oneStepLoginMessage")}
            type="error"
          />
        </>
      )}
    </Fragment>
  );
};

export default AutoUserActivate;
