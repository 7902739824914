import qs from "querystring";
import * as actionTypes from "./actionTypes";
import config from "./../../config/client";
import { ssoClient, userClient } from "./../../core/auth/services/axios";
import jwt_decode from "jwt-decode";
import { History as HistoryReact } from "history";
import Keycloak from "keycloak-js";

function storeToken(token: string, expires_in: number) {
  localStorage.setItem("token", token);
  localStorage.setItem(
    "expirationDate",
    new Date(new Date().getTime() + expires_in * 1000).toString()
  );
}

// this does not work so easily, to logout, we need to properly use the keycloak object when loging in
// and in the middlewhere..
// async function keycloakLogout(): Promise<boolean> {
//   console.log("logging out");
//   try {
//     if (keycloak.authenticated) {
//       console.log(
//         "really logging out",
//         window.location.href.replace("/dashboard", "/")
//       );

//       await keycloak.logout({
//         redirectUri: window.location.href.replace("/dashboard", "/"),
//       });
//       return true;
//     }
//   } catch (error) {
//     console.error("Failed to logout...", error);
//   }
//   return false;
// }

async function keycloakLogin(): Promise<boolean> {
  console.log("logging in");

  const keycloak = new Keycloak(config.keycloak);
  try {
    const authenticated = await keycloak.init({
      onLoad: "check-sso",
    });
    if (authenticated && keycloak.token) {
      const expiresIn =
        (keycloak.tokenParsed?.exp || 0) - (keycloak.tokenParsed?.iat || 0);
      storeToken(keycloak.token, expiresIn);
      return true;
    }
  } catch (error) {
    console.error("Failed to initialize adapter:", error);
  }
  return false;
}

const ssoUrl = config.keycloak.url;
const userUrl = config.userClient.url;
const easyUrl = config.easyUrl;

/**
 * @method authStart
 */
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

/**
 *
 * @method authSuccess
 * @param token
 * @param userId
 */
export const authSuccess = (token: string, userId: string) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
  };
};

/**
 *
 * @method authFail
 * @param error
 */
export const authFail = (error: any) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

/**
 * @method logout
 * @description clear local Storage and change state
 */
export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

/**
 *
 * @method checkAuthTimeout
 * @param expirationTime
 */
export const checkAuthTimeout = (expirationTime: number) => {
  return () => {
    setTimeout(() => {
      // TODO dispatch(logout());
    }, expirationTime * 1000);
  };
};

/**
 *
 * @method auth
 * @param email
 * @param password
 * @param isSignup
 */
export const auth = (email: string, password: string) => {
  return async (dispatch: any) => {
    dispatch(authStart());
    const url = ssoUrl + "/realms/comjoo-hub/protocol/openid-connect/token";
    const authCredentials = {
      username: email,
      password: password,
      grant_type: "password", // old sso
      client_id: "hub-connect", // old sso
    };

    try {
      const response = await ssoClient.post(url, qs.stringify(authCredentials));
      var decoded: any = jwt_decode(response.data.access_token);
      if (decoded && decoded?.changePassword === "true") {
        localStorage.setItem("tempPassword", password);
        dispatch(setAuthRedirectPath("change-password"));
      }

      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem(
        "expirationDate",
        new Date(
          new Date().getTime() + response.data.expires_in * 1000
        ).toString()
      );
      dispatch(
        authSuccess(response.data.access_token, response.data.access_token)
      );
      dispatch(checkAuthTimeout(response.data.expiresIn));
    } catch (error: any) {
      const err = error.response.data.error;
      dispatch(authFail(err));
    }
  };
};

/**
 *
 * @param path
 */
export const setAuthRedirectPath = (path: string) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

/**
 * @method authCheckState
 * @description Authentication logic
 */
export const authCheckState = () => {
  return (dispatch: any) => {
    const token = localStorage.getItem("token");
    if (!token) {
      // this is a work around so that keycloak impersonate works
      if (window.location.search === "?auth") {
        keycloakLogin()
          .then((authenticated) => {
            if (!authenticated) {
              dispatch(logout());
            } else {
              const newToken = localStorage.getItem("token");
              if (newToken) {
                dispatch(
                  authSuccess(newToken, localStorage.getItem("userId")!)
                ); //userId doesn't seem to be set anywhere...
              }
            }
          })
          .catch(() => dispatch(logout()));
      } else {
        dispatch(logout());
      }
    } else {
      const storageExpirationDate = localStorage.getItem("expirationDate");
      if (
        storageExpirationDate !== null &&
        storageExpirationDate !== undefined
      ) {
        const expirationDate = new Date(storageExpirationDate);
        if (expirationDate <= new Date()) {
          dispatch(logout());
        } else {
          const userId = localStorage.getItem("userId")!; //doesn't seem to be set anywhere...
          dispatch(authSuccess(token, userId));
          dispatch(
            checkAuthTimeout(
              (expirationDate.getTime() - new Date().getTime()) / 1000
            )
          );
        }
      }
    }
  };
};

/**
 * @function resetPassword
 * @description reset password
 */
export const resetPassword = (email: string) => {
  return async (dispatch: any) => {
    let url = `${userUrl}/password/reset`;

    const resetCredentials = {
      email: email,
      appName: "EASY",
    };

    try {
      await userClient.post(url, qs.stringify(resetCredentials), config);
      dispatch(resetPasswordSuccess());
    } catch (error) {
      dispatch(resetPasswordFail());
    }
  };
};

/**
 *
 * @method resetPasswordSuccess
 */
export const resetPasswordSuccess = () => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    resetPasswordSuccess: 1,
  };
};

/**
 *
 * @method resetPasswordFail
 */
export const resetPasswordFail = () => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    resetPasswordSuccess: 2,
  };
};

/**
 * @function setNewPassword
 * @description set new password after password forget
 */
export const setNewPassword = (
  password: string,
  token: string,
  project: string
) => {
  return async (dispatch: any) => {
    let url = `${userUrl}/password/reset/${token}`;
    if (project) {
      url = `${userUrl.replace(
        "user",
        project + ".user"
      )}/password/reset/${token}`;
    }

    const resetCredentials = {
      password: password,
    };

    try {
      await userClient.patch(url, qs.stringify(resetCredentials), config);

      dispatch(setNewPasswordSuccess());
    } catch (error) {
      dispatch(setNewPasswordFail());
    }
  };
};

/**
 * @function setNewPassword
 * @description
 */
export const setNewPasswordSuccess = () => {
  return {
    type: actionTypes.SET_NEW_PASSWORD_SUCCESS,
    newPasswordSuccess: true,
  };
};

/**
 * @function setNewPassword
 * @description
 */
export const setNewPasswordFail = () => {
  return {
    type: actionTypes.SET_NEW_PASSWORD_FAIL,
    newPasswordSuccess: false,
  };
};

/**
 * @function validateToken
 * @description validate token from email get params if validate token
 */
export const validateToken = (token: string, project: string) => {
  return async (dispatch: any) => {
    let url = `${userUrl}/password/reset/${token}`;
    if (project) {
      url = `${userUrl.replace(
        "user",
        project + ".user"
      )}/password/reset/${token}`;
    }

    try {
      const response = await userClient.get(url);

      if (response.status === 200) {
        dispatch(validateTokenSuccess());
      } else if (response.status === 406) {
        dispatch(validateTokenFail());
      }
    } catch (error) {
      // TODO set api is not available or something dispatch(validateTokenFail());
    }
  };
};

/**
 * @function validateTokenSuccess
 * @description
 */
export const validateTokenSuccess = () => {
  return {
    type: actionTypes.VALIDATE_TOKEN_SUCCESS,
    validateTokenSuccess: true,
  };
};

/**
 * @function validateTokenFail
 * @description
 */
export const validateTokenFail = () => {
  return {
    type: actionTypes.VALIDATE_TOKEN_FAIL,
    validateTokenSuccess: false,
  };
};

/**
 * @function setPassword
 * @description set password
 */
export const setPassword = (password: string) => {
  return async (dispatch: any) => {
    const url = `${userUrl}/password/change/`;
    const credentials = {
      newPassword: password,
      oldPassword: localStorage.getItem("tempPassword"),
    };

    try {
      const response = await ssoClient.post(url, qs.stringify(credentials));
      if (response) {
        // response.status === 200 && response.data
        localStorage.removeItem("tempPassword");
        // login again after repsonse true
        const tokenOld = localStorage.getItem("token");
        if (tokenOld !== null) {
          var decoded: any = jwt_decode(tokenOld);
          dispatch(auth(decoded.email, password));
        }
      }
    } catch (error) {
      // TODO set api is not available or something dispatch(validateTokenFail());
    }
  };
};

/**
 * @function changePassword
 * @description set password
 */
export const changePassword = (password: string, history: HistoryReact) => {
  return async (dispatch: any) => {
    const url = `${easyUrl}/patients/change-password`;
    const credentials = {
      newPassword: password,
    };

    try {
      const response = await ssoClient.put(url, qs.stringify(credentials));
      if (response) {
        // response.status === 200 && response.data
        // login again after repsonse true
        const tokenOld = localStorage.getItem("token");
        if (tokenOld !== null) {
          var decoded: any = jwt_decode(tokenOld);
          dispatch(auth(decoded.email, password));
          history.push("/dashboard");
        }
      }
    } catch (error) {
      // TODO set api is not available or something dispatch(validateTokenFail());
    }
  };
};

/**
 * @function setPasswordSuccess
 * @description set password success
 */
export const setPasswordSuccess = () => {
  return {
    type: actionTypes.SET_PASSWORD_SUCCESS,
    validateTokenSuccess: false,
  };
};

/**
 * @function setPasswordFail
 * @description set password fail
 */
export const setPasswordFail = () => {
  return {
    type: actionTypes.SET_PASSWORD_FAIL,
    validateTokenSuccess: false,
  };
};
